import React, { Component } from 'react';
import PropTypes from "prop-types";

import { apiProvider } from '../../api/core';
import { loadTawkTo } from '../TawkTo/TawkTo';
import { ToTheTopBtn } from '../SharedComponents/Buttons';
import ProviderLogos from "./ProviderLogos";
import LatestLocations from "./LatestLocations";
import PopularAreas from "./PopularAreas";
import BottomSeo from "./BottomSeo";
import AboutUs from "./AbouUs";
import MainSearchBlock from './MainSearchBlock/MainSearchBlock';
import LocationNavigationSection from "./LocationNavigation";
import Benefits from './Benefits';
import ListViewBanner from './ListViewBanner/ListViewBanner';
import SearchAgent from './SearchAgent';
import FAQSection from '../InfoSections/FAQSection';

import './index.sass';

// if (process.env.NODE_ENV !== 'production') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//     include: [/^pure/, /^Connect/, /^ConnectFunction/],
//     onlyLogs: true,
//     titleColor: "green",
//     diffNameColor: "aqua",
//     logOnDifferentValues: true,
//     trackAllPureComponents: true,
//   });
// }


class EntryPage extends Component {

  state = {
    showParkingLogos: false,
    latestLocations: [],
  };

  componentDidMount() {
    const { latestLocationsEndpoint } = this.props;

    loadTawkTo(this.props.siteKey);
    latestLocationsEndpoint && apiProvider.getAll(latestLocationsEndpoint)
                                          .then(response => response && this.setState({ latestLocations: response }));
  }

  handleParkingLogos = () => this.setState({ showParkingLogos: !this.state.showParkingLogos });


  render() {
    const {
            aboutUsContent,
            aboutUsHeadline,
            advantagesTranslations,
            allCountries,
            bottomSeo,
            bottomSeoBody,
            bottomseoCompleteBody,
            bottomseoHeadline,
            chooseProvinceJsArgs,
            currentLocation,
            navigationSeoContent,
            searchAgentHeadline,
            searchAgentContent,
            mainHeadline,
            mapImg,
            newsAvailable,
            newSearchAgentPath,
            newsletterPath,
            popularDistricts,
            providerLogos,
            providerParkingLogos,
            searchBlockHeader,
            signUpTeaserPass,
            siteKey,
            subSite,
            latestLocationsHeadline,
            searchAreaConfiguration,
            country,
            updatedKinds,
            currentLocalePathSegment,
            newsAllLink,
            contactInfoPhone,
            contactInfoPhoneWithoutCode,
            searchQueryString,
            entryPageQuestionsAnswers,
            newsArticlesPreviewPath,
            visitorToken,
          } = this.props;


    const { latestLocations } = this.state;


    return (
      <div className="entry-pages">
        <div className="entry-pages__content">
          <MainSearchBlock
            mainHeadline={mainHeadline}
            searchBlockHeader={searchBlockHeader}
            siteKey={siteKey}
            subSite={subSite}
            searchAreaConfiguration={searchAreaConfiguration}
            updatedKinds={updatedKinds}
            contactInfoPhone={contactInfoPhone}
            contactInfoPhoneWithoutCode={contactInfoPhoneWithoutCode}
            searchQueryStringFromGoogle={searchQueryString}
          />
          <LocationNavigationSection
            navigationSeoContent={navigationSeoContent}
            searchAgentHeadline={searchAgentHeadline}
            searchAgentContent={searchAgentContent}
            newSearchAgentPath={newSearchAgentPath}
            mapImg={mapImg}
            siteKey={siteKey}
            chooseProvinceJsArgs={chooseProvinceJsArgs}
            subSite={subSite}
            handleParkingLogos={this.handleParkingLogos}
            allCountries={allCountries}
            updatedKinds={updatedKinds}
            searchAreaConfiguration={searchAreaConfiguration}
            currentLocalePathSegment={currentLocalePathSegment}
          />
          <Benefits advantagesTranslations={advantagesTranslations} country={country} preselectedKind={searchAreaConfiguration.searchOptions.preselectedKinds} />
          <ProviderLogos
            currentLocation={currentLocation}
            providerLogos={providerLogos}
            providerParkingLogos={providerParkingLogos}
            signUpTeaserPass={signUpTeaserPass}
            showParkingLogos={this.state.showParkingLogos}
            siteKey={siteKey}
          />
          { latestLocations.length > 0 &&
            <LatestLocations
              latestLocations={latestLocations}
              headline={latestLocationsHeadline}
              siteKey={siteKey}
              section={subSite}
            />
          }

          <PopularAreas popularDistricts={popularDistricts} />

          { searchAgentContent && searchAgentHeadline &&
            <SearchAgent
              siteKey={siteKey}
              newSearchAgentPath={newSearchAgentPath}
              searchAgentText={searchAgentContent}
              searchAgentHeadline={searchAgentHeadline}
            />
          }

          <AboutUs
            aboutUsHeadline={aboutUsHeadline}
            aboutUsContent={aboutUsContent}
            currentLocation={currentLocation}
            newsAvailable={newsAvailable}
            siteKey={siteKey}
            newsletterPath={newsletterPath}
            newsAllLink={newsAllLink}
            newsArticlesPreviewPath={newsArticlesPreviewPath}
          />

          <ListViewBanner signUpTeaserPass={signUpTeaserPass} siteKey={siteKey} visitorToken={visitorToken} />

          { bottomSeo &&
            <BottomSeo
            bottomSeoBody={bottomSeoBody}
            bottomseoCompleteBody={bottomseoCompleteBody}
            bottomseoHeadline={bottomseoHeadline}
            />
          }
        </div>


        {entryPageQuestionsAnswers && entryPageQuestionsAnswers.length > 0 && (
          <FAQSection
            richSnippetQuestions={entryPageQuestionsAnswers}
            title={I18n.t('provider.teaser.faq_questions.title-entry-page')}
            isRequestFromPrerender={false}
          />
        )}
        <ToTheTopBtn />
      </div>
    );
  }
}

export default EntryPage;

EntryPage.propTypes = {
  aboutUsContent: PropTypes.string,
  aboutUsHeadline: PropTypes.string,
  advantagesTranslations: PropTypes.string,
  backgroudImages: PropTypes.arrayOf(PropTypes.string),
  bottomSeo: PropTypes.bool,
  bottomSeoBody: PropTypes.string,
  bottomseoCompleteBody: PropTypes.string,
  bottomseoHeadline: PropTypes.string,
  chooseProvinceJsArgs: PropTypes.shape({
    basePathsForKinds: PropTypes.object,
    districtBasePath: PropTypes.string,
    globalStatsUrl: PropTypes.string,
    localePart: PropTypes.string,
    provinceBasePath: PropTypes.string,
    statsProvincesOrder: PropTypes.string,
    statsUrl: PropTypes.string,
  }),
  contactForm: PropTypes.object,
  contactFormAvailable: PropTypes.bool,
  currentLocation: PropTypes.string,
  navigationSeoContent: PropTypes.string,
  searchAgentHeadline: PropTypes.string,
  searchAgentContent: PropTypes.string,
  latestLocations: PropTypes.arrayOf(PropTypes.object),
  latestLocationsHeadline: PropTypes.string,
  logo: PropTypes.string,
  mainHeadline: PropTypes.string,
  mapImg: PropTypes.string,
  nav: PropTypes.shape({
    left: PropTypes.object,
    right: PropTypes.object,
  }),
  newsAvailable: PropTypes.bool,
  newSearchAgentPath: PropTypes.string,
  popularDistricts: PropTypes.arrayOf(PropTypes.object),
  providerLogos: PropTypes.arrayOf(PropTypes.array),
  providerParkingLogos: PropTypes.arrayOf(PropTypes.string),
  providerTrustedLogos: PropTypes.arrayOf(PropTypes.object),
  searchAreaConfiguration: PropTypes.object,
  searchBlockHeader: PropTypes.string,
  signUpTeaserPass: PropTypes.string,
  siteKey: PropTypes.string,
  subSite: PropTypes.string,
  currentLocalePathSegment: PropTypes.string,
  trustedLogosUrl: PropTypes.string,
};


EntryPage.defaultProps = {
  aboutUsContent: '',
  aboutUsHeadline: '',
  advantagesTranslations: '',
  backgroudImages: [],
  bottomSeo: false,
  bottomSeoBody: '',
  bottomseoCompleteBody: '',
  bottomseoHeadline: '',
  chooseProvinceJsArgs: {
    basePathsForKinds: {},
    districtBasePath: '',
    globalStatsUrl: '',
    localePart: '',
    provinceBasePath: '',
    statsProvincesOrder: '',
    statsUrl: '',
  },
  contactForm: {},
  currentLocation: '',
  navigationSeoContent: '',
  searchAgentHeadline: '',
  searchAgentContent: '',
  latestLocations: [],
  latestLocationsHeadline: '',
  logo: '',
  mainHeadline: '',
  mapImg: '',
  nav: { left: {}, right: {} },
  newsAvailable: false,
  newSearchAgentPath: '',
  popularDistricts: [],
  providerLogos: [],
  providerParkingLogos: [],
  providerTrustedLogos: [],
  searchAreaConfiguration: {},
  searchBlockHeader: '',
  signUpTeaserPass: '',
  siteKey: '',
  subSite: '',
  currentLocalePathSegment: '',
  trustedLogosUrl: '',
};
