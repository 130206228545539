import React from 'react';
import axios from 'axios';
import { emitter, Experiment, Variant } from "@marvelapp/react-ab-test";
import { isMobileOnly } from 'react-device-detect';

import searchAgentBanner from '../../../src/images/entry_pages/search-agent-banner.svg';
import * as helpers from '../../../utils/helpers';

import './index.sass';


emitter.defineVariants(
  'search-agent-dv-link',
  ['sa_dv_new', 'sa_dv_old'],
  [50, 50]
);



const SearchAgent = ({ siteKey, newSearchAgentPath, searchAgentText, searchAgentHeadline, page, richSnippetsAvailable, visitorToken }) => {

  const marginBottom = { marginBottom: !richSnippetsAvailable ? '64px' : 0 };
  const checkForAdditionlaText = siteKey === 'global';
  const isDetailView = page === 'detail-view';
  const showABTest = !!(siteKey === 'de' && isDetailView && visitorToken);


  const handleClick = (e) => {
    e.preventDefault();

    const variant = emitter.getActiveVariant('search-agent-dv-link');
    const params = { ab_case: { code: variant, user_token: visitorToken } }

    axios({
      method:'post',
      url:'api/frontend/ab_test_cases',
      baseURL: '/',
      data: params,
    })
      .then(response => {
        window.open(newSearchAgentPath, '_blank');
      })
      .catch(error => {
        console.log(error);
        window.open(newSearchAgentPath, '_blank');
      });
  };


  return (
    <div className="dv-info-section-wrapper dv-search-agent container" style={ isDetailView ? marginBottom : {}}>
      <div className="dv-search-agent__wrapper">
        <div className="dv-search-agent__image-text-wrapper">
          { !isDetailView && !isMobileOnly &&
            <div className="dv-search-agent__image-wrapper">
              <img data-src={searchAgentBanner}
                   alt="search-banner illustration"
                   className="lazyload"
                   data-expand="100"
                   data-sizes="auto"
                   width={140}
                   height="auto"
              />
            </div>
          }

          <div className="dv-search-agent__text-wrapper">
            <p className="dv-search-agent__headline">{ searchAgentHeadline }</p>
            <div>
              <div className="dv-search-agent__text" dangerouslySetInnerHTML={{ __html: helpers.parseMd(searchAgentText) }} />
              { checkForAdditionlaText &&
                <div className="dv-search-agent__text">
                  { I18n.t('lb_showroom.list-view.search_agent.description') }
                </div>
              }
            </div>

          </div>
        </div>

        <div className="dv-search-agent__buttons-block">
          <a href={newSearchAgentPath} target="_blank" className='dv-search-agent__button sa-button' onClick={showABTest ? handleClick : null}>
            <span>
              { siteKey === 'dk'
                ? I18n.t('search_agents.new.standard_search_agent')
                : showABTest
                ?
                  <Experiment name="search-agent-dv-link">
                    <Variant name="sa_dv_new">
                      Benachrichtigungen einstellen
                    </Variant>
                    <Variant name="sa_dv_old">
                      { I18n.t('search_agents.new.create_a_search_agent') }
                    </Variant>
                  </Experiment>
                : I18n.t('search_agents.new.create_a_search_agent')
              }
              {/*{ checkDk ? I18n.t('search_agents.new.standard_search_agent') : I18n.t('search_agents.new.create_a_search_agent') }*/}
            </span>
          </a>
        </div>
      </div>
    </div>
  )
};


export default React.memo(SearchAgent);
